<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Criar um novo produto</h3>
      <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>
    <!-- Card-->
    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex lg:flex-row flex-col">

        <div class="flex flex-col w-full">

          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input type="datalist" id="evento" v-model="data.evento" label="Evento" placeholder="Selecione um evento"
                :data="eventos" />
            </div>
            <div class="w-full p-2">
              <Input type="datalist" id="tipo" v-model="data.tipo_produto" label="Tipo de produto"
                placeholder="Selecione um tipo" :data="tipos" />
            </div>
          </div>


          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input v-if="!data.tipo_produto" type="datalist" id="subtipo" :readonly="true"
                v-model="data.subtipo_produto" label="Subtipo de produto" placeholder="Selecione um subtipo" />
              <Input v-else-if="subtipos.length == 0" type="datalist" id="subtipo" :readonly="true"
                v-model="data.subtipo_produto" label="Subtipo de produto" placeholder="Selecione um subtipo"
                :data="subtipos" />
              <Input v-else type="datalist" id="subtipo" v-model="data.subtipo_produto" label="Subtipo de produto"
                placeholder="Selecione um subtipo" :data="subtipos" />
            </div>
          </div>

          <!-- SKUS -->
          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input type="default" v-model="data.cod_produto" label="Código" placeholder="Código do produto" />
            </div>
            <div class="w-full p-2">
              <Input type="default" v-model="data.sku_magento" label="Código do Magento"
                placeholder="Código do Magento" />
            </div>
          </div>


          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input type="money" v-model="data.valor" label="Valor" placeholder="Valor" />
            </div>
            <div class="w-full p-2">
              <Input type="number" v-model="data.quantidadeProduto" label="Quantidade"
                placeholder="Quantidade do produto" />
            </div>

          </div>

          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input type="select" v-model="data.sexo" label="Sexo" placeholder="Selecione um tipo" :data="[
                { label: 'M', value: 'M' },
                { label: 'F', value: 'F' },
                { label: 'I', value: 'I' },
              ]" />
            </div>
            <div class="w-full p-2">
              <Input type="number" v-model="data.lote" label="Lote" placeholder="Lote" />
            </div>
            <div class="w-full p-2">
              <Input type="tax" v-model="data.tx_conveniencia" label="Taxa de Conveniência"
                placeholder="Taxa de Conveniência" />
            </div>
          </div>

          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <Input type="textarea" v-model="data.detalhes" label="Detalhes" placeholder="Detalhes do produto" />
            </div>
          </div>

          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2 lg:max-w-sm xl:max-w-lg">
              <label for="regras" class="block relative">
                <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
                  Regras do Produto
                </span>
                <QuillEditor theme="snow" :toolbar="toolbarOptions" :options="editorOptions"
                  v-model:content="data.regras_produto" id="regras" style="height: 200px;" class="" contentType="html" />
              </label>
            </div>
          </div>

          <div class="flex lg:flex-row flex-col w-full">
            <div class="w-full p-2">
              <label>
                <input type="checkbox" v-model="data.promoter" class="mr-2" />
                Criar produto para promoter
              </label>
            </div>
          </div>
        </div>
        <!-- Novos Campos + Imagem-->

        <div class="flex flex-col lg:w-2/5">
          <div class="w-full p-2">
            <Input type="datalist" v-model="data.pdv" label="PDV" placeholder="Selecione um PDV" :data="pdv" id="pdv" />
          </div>
          <div class="w-full p-2">
            <Input type="datalist" v-model="data.id_lote" label="Lotes" placeholder="Selecione um Lote" :data="lotes"
              id="Lote" />
          </div>

          <div class="w-full p-2">
            <Input type="datalist" v-model="data.variacao" label="Variações" placeholder="Selecione uma Variação"
              :data="variacoes" id="Variacão" />
          </div>


          <div class="w-full p-2">
            <Input type="datalist" v-model="data.dia" label="Dias" placeholder="Selecione um Dia" :data="dias"
              id="Dia" />
          </div>


          <div class="w-full p-2">
            <Input type="datalist" v-model="data.setor" label="Setores" placeholder="Selecione um Setor" :data="setores"
              id="Setor" />
          </div>


          <div class="flex flex-col  mt-1 mx-3 gap-3 mt-6 items-center">
            <img class="flex-auto rounded-lg shadow-lg w-36 h-36" :src="data.file
              ? readURL(data.file)
              : 'https://via.placeholder.com/400x400'
              " />
            <div class="lg:w-52 flex justify-center">
              <button class="
                  bg-primary
                  rounded-md
                  shadow-md
                  transform
                  hover:scale-105
                  px-5
                  py-2
                  static
                  flex
                  justify-center
                  items-center
                  font-bold
                " style="bottom: 20px; left: 20px">
                <label class="text-white" for="logo">Adicionar</label>
                <i class="ml-3 text-white gg-camera"></i>
                <input @change="changeImage" class="absolute opacity-0 bton" id="logo" type="file" ref="button"
                  style=" left: -1px; bottom: -35px;" />
              </button>
            </div>
          </div>

        </div>
      </div>



      <!-- Botao-->
      <div class="flex justify-end mt-5">
        <Button @click="create" color="primary" text="Criar" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref, watch } from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";
import { toDate } from "../../services/formater";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "CriarProduto",
  components: {
    Input,
    Button,
    QuillEditor,
  },
  data() {
    return {
      isMobile: false,
      toolbarOptions: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ],
      editorOptions: {
        modules: {
          autoformat: {
            remove: false,
            rules: [
              { newline: true, format: [] }
            ]
          }
        }
      },
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const data = ref({
      "evento": "",
      "tipo_produto": "",
      "subtipo_produto": "",
      "cod_produto": "",
      "sku_magento": "",
      lote: 1,
      sexo: 'I',
      detalhes: "",
      "regras_produto": "",

      "valor_referencia": 0,
      valor: "",
      "quantidadeProduto": "",
      "tx_conveniencia": 0,
      file: "",
      promoter: false
    });
    const eventos = ref([]);
    const tipos = ref([]);
    const subtipos = ref([]);
    const subtiposRes = ref([]);
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();

    // Novos Campos

    const dias = ref([]);
    const pdv = ref([]);
    const lotes = ref([]);
    const variacoes = ref([]);
    const setores = ref([]);


    const create = async () => {


      let control = 0
      if (data.value['regras_produto'] === ''){
        data.value['regras_produto'] = 'temp'
        control = 1
      }

      const validate = isEmpty(data.value);


      if (validate) {
        data.value['regras_produto'] = ''
        alert.open("Atenção!", validate, "warning");
      } else {

        if (control === 1){
          data.value['regras_produto'] = ''
        }

        const obj = ref({});
        obj.value = {
          "qnt_por_produto": parseInt(data.value.quantidadeProduto),
          ...data.value
        };


        delete obj.value.evento;

        delete obj.value.quantidadeProduto;

        delete obj.value.tipo_produto;

        delete obj.value.subtipo_produto;

        delete obj.value.codigo_produto;

        if(!obj.value.regras_produto){
          delete obj.value.regras_produto
          delete data.value.regras_produto
        }

        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == data.value.evento
        );
        eventoSelecionado
          ? (obj.value["id_evento"] = eventoSelecionado.value)
          : (obj.value["id_evento"] = "");

        const [tipoSelecionado] = tipos.value.filter(
          (tipo) => tipo.label == data.value.tipo_produto
        );
        tipoSelecionado
          ? (obj.value["id_tipo_produto"] = tipoSelecionado.value)
          : (obj.value["id_tipo_produto"] = "");

        const [subTipoSelecionado] = subtipos.value.filter(
          (subtipo) => subtipo.label == data.value.subtipo_produto
        );
        subTipoSelecionado
          ? (obj.value["id_subtipo_produto"] = subTipoSelecionado.value)
          : (obj.value["id_subtipo_produto"] = "");
        //Configurar os campos: valor e valor_referencia

        //novos campos

        const [diaSelecionado] = dias.value.filter((tipo) => tipo.label == data.value.dia)
        diaSelecionado ? (obj.value["dia"] = diaSelecionado.value)
          : (obj.value["dia"] = 0);

        const [loteSelecionado] = lotes.value.filter((tipo) => tipo.label == data.value['id_lote'])
        loteSelecionado ? (obj.value["id_lote"] = loteSelecionado.value)
          : (obj.value["id_lote"] = 0);

        const [setorSelecionado] = setores.value.filter((tipo) => tipo.label == data.value.setor)
        setorSelecionado ? (obj.value["setor"] = setorSelecionado.value)
          : (obj.value["setor"] = 0);


        const [pdvSelecionado] = pdv.value.filter((tipo) => tipo.label == data.value.pdv)
        pdvSelecionado ? (obj.value["pdv"] = pdvSelecionado.value)
          : (obj.value["pdv"] = 0);


        const [variacaoSelecionado] = variacoes.value.filter((tipo) => tipo.label == data.value.variacao)
        variacaoSelecionado ? (obj.value["variacao"] = variacaoSelecionado.value)
          : (obj.value["variacao"] = 0);


        if (obj.value.id_tipo_produto == 102) {


          const valorFloat = obj.value.valor.split(" ", 2);
          //const aux = 0;
          obj.value["valor"] = 0;


          valorFloat[1] = valorFloat[1].replace(".", "");
          obj.value["valor_referencia"] = parseFloat(valorFloat[1].replace(",", "."))

        } else {

          const valorFloat = obj.value.valor.split(" ", 2);

          valorFloat[1] = valorFloat[1].replace(".", "")

          obj.value.valor = parseFloat(valorFloat[1].replace(",", "."))
        }

        if (obj.value.valor == ".") {

          obj.value.valor = obj.value.valor.replace(".", '0')
        }
        //campos que são retornados pelo banco de dados
        obj.value.promoter


        loader.open();
        const objRequest = new FormData();
        const item = { ...obj.value };
        for (const key in item) {
          objRequest.append(key, item[key]);
        }
        try {
          await POST("produto", objRequest);
          alert.open("Sucesso!", `Produto criado com sucesso!`, "success");
          loader.close();
          router.push(`/produtos/${obj.value.id_evento}`);
        } catch (e) {
          alert.open("Atenção!", e, "danger");
          loader.close();
        }
      }
    };

    const changeImage = (event) => {
      const obj = {
        "evento": data.value["evento"],
        "tipo_produto": data.value["tipo_produto"],
        "subtipo_produto": data.value["subtipo_produto"],
        "cod_produto": data.value["cod_produto"],
        "sku_magento": data.value["sku_magento"],
        lote: data.value.lote,
        sexo: data.value.sexo,
        detalhes: data.value.detalhes,
        valor: data.value.valor,
        "valor_referencia": data.value["valor_referencia"],
        "quantidadeProduto": data.value["quantidadeProduto"],
        "tx_conveniencia": data.value["tx_conveniencia"],
        promoter: data.value.promoter,
        file: event.target.files[0],
        pdv: data.value["pdv"],
        dia: data.value["dia"],
        setor: data.value["setor"],
        "id_lote": data.value["id_lote"],
        variacao: data.value["variacao"],
        "regras_produto": data.value['regras_produto'],
      };
      data.value = obj;
    };



    onMounted(async () => {
      try {
        loader.open();
        const eventosRes = await GET(`event`);
        eventosRes.forEach((evento) =>
          eventos.value.push({
            label: evento.nome + " - " + toDate(evento.data),
            value: evento.id,
          })
        );
        //TODO: buscar os tipos e subtipos de produto para o datalist

        const tiposRes = await GET(`tipo_produto`);

        tiposRes.forEach((tipo) =>
          tipos.value.push({ label: tipo.nome, value: tipo.id })
        );

        subtiposRes.value = await GET(`subtipo_produto`);

        const config = await GET('produto/config')

        for (const tipo of config.data) {


          switch (tipo.nome) {
            case 'Dias':
              dias.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Lotes':
              lotes.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Pdv':
              pdv.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Variacoes':
              variacoes.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Setores':
              setores.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
          }
        }

        //fazer tratamento....
        if (route.params.id) {


          const obj = { ...(await GET(`produto/${route.params.id}`)) };


          data.value = {
            ...obj,
            evento: obj["id_evento"],
            "tipo_produto": obj["id_tipo_produto"],
            "subtipo_produto": obj["id_subtipo_produto"],
            "codigo_produto": obj["cod_produto"],
            "quantidadeProduto": obj['qnt_por_produto']
          }

          delete data.value['qnt_por_produto']



          data.value.file = `https://santocartao-files.s3.amazonaws.com/product-pictures/${data.value.cod_produto}.jpg`;

          const [eventoSelecionado] = eventos.value.filter(
            (evento) => evento.value == data.value.evento
          );
          eventoSelecionado
            ? (data.value["evento"] = eventoSelecionado.label)
            : (data.value["evento"] = "");

          const [tipoSelecionado] = tipos.value.filter(
            (tipo) => tipo.value == data.value.tipo_produto
          );
          tipoSelecionado
            ? (data.value["tipo_produto"] = tipoSelecionado.label)
            : (data.value["tipo_produto"] = "");

          const [subTipoSelecionado] = subtiposRes.value.filter(
            (subtipo) => subtipo.id == data.value["subtipo_produto"]
          );

          subTipoSelecionado
            ? (data.value["subtipo_produto"] = subTipoSelecionado.nome)
            : (data.value["subtipo_produto"] = "");

          if (data.value.tipo_produto == 102) {

            const dinheiro = "R$ " + data.value.valor_referencia * 100;
            const x = dinheiro.slice(3, dinheiro.length - 2) + "," + dinheiro.slice(dinheiro.length - 2);
            data.value["valor_referencia"] = "R$ " + x;

          } else {
            const dinheiro = "R$ " + data.value.valor * 100;

            const x = dinheiro.slice(3, dinheiro.length - 2) + "," + dinheiro.slice(dinheiro.length - 2);

            data.value.valor = "R$ " + x;
          }
          delete data.value.taxa_sc
          delete data.value.id

          //novos campos


          const [diaSelecionado] = dias.value.filter((tipo) => tipo.value == data.value.dia)
          diaSelecionado ? (data.value["dia"] = diaSelecionado.label)
            : (data.value["dia"] = "");

          const [loteSelecionado] = lotes.value.filter((tipo) => tipo.value == data.value['id_lote'])
          loteSelecionado ? (data.value["id_lote"] = loteSelecionado.label)
            : (data.value["id_lote"] = "");

          const [setorSelecionado] = setores.value.filter((tipo) => tipo.value == data.value['setor'])
          setorSelecionado ? (data.value["setor"] = setorSelecionado.label)
            : (data.value["setor"] = "");


          const [pdvSelecionado] = pdv.value.filter((tipo) => tipo.value == data.value.pdv)
          pdvSelecionado ? (data.value["pdv"] = pdvSelecionado.label)
            : (data.value["pdv"] = "");


          const [variacaoSelecionado] = variacoes.value.filter((tipo) => tipo.value == data.value.variacao)
          variacaoSelecionado ? (data.value["variacao"] = variacaoSelecionado.label)
            : (data.value["variacao"] = "");






        }

        if (localStorage.getItem("event_santo_cartao") != 'null') {
          const [eventoSelecionado] = eventos.value.filter(
            (evento) => evento.value == localStorage.getItem("event_santo_cartao")
          );

          data.value.evento = eventoSelecionado.label

        }
        loader.close();
      } catch (error) {
        loader.close();
        alert.open(
          "Atenção!",
          error.message,
          "warning"
        );
      }
    });

    const changeSubTipo = async (id) => {
      if (id == 0) {
        subtipos.value.length = 0;
        data.value["subtipo_produto"] = ""
      }
      subtiposRes.value.forEach((subtipo) => {
        if (subtipo.id_tipo_produto == id)
          subtipos.value.push({ label: subtipo.nome, value: subtipo.id });
      });

      if (id == 102) {
        if (data.value.detalhes.substring(0, 11) != "Promoter - ")
          data.value.detalhes = "Promoter - " + data.value.detalhes
      } else {
        //remover Promoter caso mude...
        if (data.value.detalhes.substring(0, 11) == "Promoter - ") {
          const str = data.value.detalhes.split(" ")
          data.value.detalhes = str[2]
        }
      }

      if (subtipos.value.length == 0 && id != 0) {
        alert.open(
          "Atenção!",
          "Não existe subtipos para esse Tipo de produto",
          "warning"
        );
      }
    };

    const readURL = (file) => {
      if (typeof file == "string") {
        return file;
      }
      return window.URL.createObjectURL(file);
    };


    watch(() => data.value.tipo_produto, () => {


      subtipos.value = []
      data.value['subtipo_produto'] = ''

      const [tipoSelecionado] = tipos.value.filter(
        (tipo) => tipo.label == data.value.tipo_produto
      );
      if (tipoSelecionado == undefined) {
        changeSubTipo(0);
      } else {
        changeSubTipo(tipoSelecionado.value);
      }
    });

    return {
      data,
      create,
      changeImage,
      readURL,
      eventos,
      tipos,
      subtipos,
      subtiposRes,
      dias,
      pdv,
      lotes,
      variacoes,
      setores,
    };
  },


};
</script>

<style>
.h-px {
  height: 350px;
}

.bton {
  z-index: 5;
  width: 140px;
  height: 75px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 40px;
}
</style>